




















import { Vue, Component } from 'vue-property-decorator';
import router from '@/router';
import { AddressItem, deleteAddress, getAddressList, serDefaultAddress } from '@/api/user/address';
import Error from '@/components/Base/BaseError/index.vue';
import { throttle } from 'lodash';
import BaseDialog from '@/components/Base/BaseDialog/index';

import { getBuyAddress, removeBuyAddress, setBuyAddress } from '@/utils/storage';
type IAddress = { id: string; name: string; tel: string; address: string; isDefault: boolean };
export enum AddressOptionsEnum {
  CREATE_ORDER_SELECT = '1'
}

@Component({
  name: '',
  components: { Error }
})
export default class extends Vue {
  get handleClick() {
    return throttle(
      async (type: 'click' | 'edit', item: IAddress) => {
        if (type === 'edit') {
          this.onEdit(item);
        } else if (type === 'click') {
          this.onSelectAddress(item);
        }
      },
      100,
      {
        leading: true,
        trailing: false
      }
    );
  }

  isLoading = false;

  isEditor = false;

  current = 1;
  /* 已经处理过用于显示的列表 */
  addressList: IAddress[] = [];
  /* 未处理的列表 */
  rawAddressList: AddressItem[] = [];

  onAdd = () => router.push({ name: 'AddressEdit' });

  onEdit(item: IAddress) {
    router.push({
      name: 'AddressEdit',
      query: { id: item.id }
    });
  }

  async onDel(id: string) {
    await BaseDialog({
      title: '提示',
      message: '确定要删除该地址吗？'
    });

    await deleteAddress(id);
    this.getAddressList();

    if (this.$route.query.type === AddressOptionsEnum.CREATE_ORDER_SELECT) {
      const address = getBuyAddress();
      if (address) {
        address.addressId === id;
        removeBuyAddress();
      }
    }
  }

  async setDefault(e: IAddress) {
    if (e.isDefault) return;
    await serDefaultAddress(e.id);
    this.getAddressList();
  }

  async getAddressList() {
    this.isLoading = true;
    const {
      payload: { records }
    } = await getAddressList({}, this.current);
    this.rawAddressList = records;
    this.addressList = records.map(item => {
      return {
        id: item.addressId,
        name: item.receiverName,
        tel: item.receiverTel,
        address: item.provinceName + item.cityName + item.districtName + item.detailAddress,
        isDefault: !!item.defaulted
      };
    });
    this.isLoading = false;
  }
  /* 点击单个列表 */
  onSelectAddress(curAddress: IAddress) {
    if (this.$route.query.type === AddressOptionsEnum.CREATE_ORDER_SELECT) {
      const address = this.rawAddressList.find(item => item.addressId === curAddress.id);
      address && setBuyAddress(address);
      router.go(-1);
    }
  }

  created() {
    this.getAddressList();
  }
}
