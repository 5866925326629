import request, { baseQueryByPage } from '@/utils/request';
import { IRequestResult } from '@/api/type/tool';

// 地址项
export interface AddressItem {
  // 地区id
  addressId: string;
  // 收货人姓名
  receiverName: string;
  // 收货人电话
  receiverTel: string;
  // 邮政编码
  postalCode: string;
  // 省编码
  provinceCode: string;
  // 省名称
  provinceName: string;
  // 市区编码
  cityCode: string;
  // 市区名称
  cityName: string;
  // 区编码
  districtCode: string;
  // 区名称
  districtName: string;
  // 乡镇编码
  townCode: string;
  // 乡镇名称
  townName: string;
  // 详细地址
  detailAddress: string;
  // 是否默认地址  1 默认
  defaulted: 0 | 1;
  // 排序号
  sort?: number;
  // 其他
  [other: string]: any;
}

// 获取默认收货地址
export function getDefaultAddress(): Promise<IRequestResult<AddressItem>> {
  return request.get('/user/address/info/default', { hideMsg: true });
}

// 获取默认收货地址
export function serDefaultAddress(addressId: AddressItem['addressId']): Promise<IRequestResult<AddressItem>> {
  return request.get(`/user/address/default/${addressId}`, { hideMsg: true });
}

// 查询收货地址详情
export function getAddressById(id: string): Promise<IRequestResult<AddressItem>> {
  return request.get(`/user/address/info/${id}`, { hideMsg: true });
}

// 分页查询所有收货地址
export const getAddressList = baseQueryByPage<Record<string, any>, AddressItem>('/user/address/list');

// 删除收货地址
export function deleteAddress(id: string): Promise<IRequestResult> {
  return request.post('/user/address/delete', id.split(','), { loading: true, msgText: '删除成功' });
}

// 编辑收货地址
export function editAddress(data: AddressItem): Promise<IRequestResult<AddressItem>> {
  return request.post('/user/address/save', data, { loading: true, msgText: '保存成功' });
}

// 根据id 获取省市区四级列表
export function getAddressAreaInfo(data: string): Promise<
  IRequestResult<
    {
      areaCode: string;
      areaName: string;
    }[]
  >
> {
  return request.get(`/user/address_area/info/${String(data)}`, { hideMsg: true });
}
